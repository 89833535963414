import axios from "axios";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import instance from "../configs/api";
import { IUnit, IUnitMutation, ITotal } from "../models/Unit/types";
import { IError } from "../models/Error/types";


function useUnits(){
    const getUnits: UseQueryResult<IUnit[], IError> = useQuery(
        "unit",
        async ()=>{
            return instance.get("/unity/get").then((res)=> res.data);
        }
    )
    return {
        getUnits
    }
}
export default useUnits;