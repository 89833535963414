import { AnalyticsRounded, SettingsRounded } from "@mui/icons-material";
import HomeMiniIcon from "@mui/icons-material/HomeMini";

interface ISideItems {
  name: string;
  to: string;
  icon: (active: boolean) => React.ReactElement;
}
const items: ISideItems[] = [
  {
    name: "Meus dispositivos",
    to: "/devices",
    icon: (active) => (
      <HomeMiniIcon
        sx={(theme) => ({
          color: active
            ? theme.palette.primary.main
            : theme.palette.secondary.light,
        })}
      />
    ),
  },
  {
    name: "Meus Relatórios",
    to: "/reports",
    icon: (active) => (
      <AnalyticsRounded
        sx={(theme) => ({
          color: active
            ? theme.palette.primary.main
            : theme.palette.secondary.light,
        })}
      />
    ),
  },
  {
    name: "Configurações",
    to: "/settings",
    icon: (active) => (
      <SettingsRounded
        sx={(theme) => ({
          color: active
            ? theme.palette.primary.main
            : theme.palette.secondary.light,
        })}
      />
    ),
  },
];

export { items };
