import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Notifier, Button, FormTextField } from "..";
import { Formik, Form } from "formik";
import { useLocalStorage } from "../../hooks";
import Yup from "../../configs/yup";
import { CloseRounded } from "@mui/icons-material";

interface IAddProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function AddDevice({ setOpen }: IAddProps) {
  const { get } = useLocalStorage();
  // const { bedMutation } = useBed();
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const initialValues = {
    unity: "",
    bed: "",
  };
  const validations = Yup.object().shape({
    unity: Yup.string().required("É requerido"),
    bed: Yup.string().required("É requerido"),
  });
  function handleSubmit(values: any) {
    // bedMutation.mutate(values, {
    //   onSuccess: (response) => {
    //     setSuccess(true);
    //   },
    //   onError: (error) => {
    //     setError(true);
    //   },
    //   onSettled: () => {},
    // });
  }
  return (
    <>
      <Notifier
        msg="Unidade Criada Com Sucesso!"
        closeState={setSuccess}
        severity="success"
        open={success}
      />
      <Notifier
        msg={"Erro"}
        closeState={setError}
        severity="error"
        open={error}
      />
      {/* <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize={16} fontWeight="bold" color="secondary">
          Adicionar Dispositivo
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <CloseRounded />
        </IconButton>
      </DialogTitle> */}
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validations}
          enableReinitialize
        >
          {({}) => (
            <Form>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <FormTextField
                    name="unity"
                    label="Unidade"
                    disabled
                  ></FormTextField>
                </Grid>
                <Grid xs={12} item>
                  <FormTextField name="bed" label="Leito"></FormTextField>
                </Grid>
                <Grid xs={12} item>
                  <Button type={"submit"} loading={false}>
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </>
  );
}

export default AddDevice;
