import {
  AddCircle,
  BedRounded,
  GridViewRounded,
  HomeMini,
  LocalHospitalRounded,
  ViewListRounded,
} from "@mui/icons-material";
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { useContext } from "react";
import Add from "./Add/Add";
import { GlobalContext } from "../../contexts";
import AddDevice from "../AddDevice/AddDevice";

function SidebarSubNav() {
  const route = useLocation();
  const { view, setView } = useContext(GlobalContext);
  const [add, setAdd] = React.useState<boolean>(false);
  const [addDevice, setAddDevice] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const matches = useMediaQuery("(min-width:900px)");
  const [width, setWidth] = React.useState<string>("100%");
  React.useEffect(() => {
    if (matches) {
      setWidth("100%");
    } else {
      setWidth("100vw");
    }
  }, [matches]);
  function handleAdd(): void {
    setAdd(true);
    setAddDevice(false);
  }
  function handleAddDevice(): void {
    setAdd(false);
    setAddDevice(true);
  }
  function handleClose(): void {
    setAdd(false);
    setAddDevice(false);
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseAnchor}
        onClick={handleCloseAnchor}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 0,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(90deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <MenuItem onClick={handleAdd}>
          <ListItemIcon>
            <LocalHospitalRounded />
          </ListItemIcon>
          Adicionar Unidade
        </MenuItem>
        {/* <Divider /> */}
        {/* <MenuItem onClick={handleAddDevice}>
          <ListItemIcon>
            <HomeMini />
          </ListItemIcon>
          Adicionar Dispositivo
        </MenuItem> */}
      </Menu>
      <Dialog open={addDevice} onClose={handleClose}>
        <AddDevice setOpen={setAddDevice} />
      </Dialog>
      <Dialog open={add} onClose={handleClose}>
        <Add setAdd={setAdd} />
      </Dialog>
      <Box
        sx={{
          padding: "4px 40px 4px 22px",
          backgroundColor: "#FCFCFD",
          width: width,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #F5F5F5",
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <Box>
          <Typography fontSize={12} fontWeight="bold">
            Meus Dispositivos /
          </Typography>
        </Box>
        <List>
          <ListItemIcon
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Tooltip title="Adicionar Ala">
              <AddCircle
                onClick={() => setAdd(true)}
                sx={{
                  fontSize: "1.1em",
                  color: (theme) => theme.palette.primary.light,
                  trasition: ".3s",
                  cursor: "pointer",
                  "&:hover": {
                    color: (theme) => theme.palette.primary.main,
                  },
                  "&.Mui-selected": {
                    color: (theme) => theme.palette.primary.main,
                  },
                }}
              />
            </Tooltip>
            <Tooltip title="Visualizar por Lista">
              <ViewListRounded
                onClick={() => setView("list")}
                className={view === "list" ? "Mui-selected" : ""}
                sx={{
                  fontSize: "1.1em",
                  color: (theme) => theme.palette.primary.light,
                  trasition: ".3s",
                  cursor: "pointer",
                  "&:hover": {
                    color: (theme) => theme.palette.primary.main,
                  },
                  "&.Mui-selected": {
                    trasition: ".3s",
                    color: (theme) => theme.palette.primary.main,
                  },
                }}
              />
            </Tooltip>
            <Tooltip title="Visualizar por Bloco">
              <GridViewRounded
                onClick={() => setView("block")}
                className={view === "block" ? "Mui-selected" : ""}
                sx={{
                  fontSize: "1.1em",
                  color: (theme) => theme.palette.primary.light,
                  trasition: ".3s",
                  cursor: "pointer",
                  "&:hover": {
                    color: (theme) => theme.palette.primary.main,
                  },
                  "&.Mui-selected": {
                    trasition: ".3s",
                    color: (theme) => theme.palette.primary.main,
                  },
                }}
              />
            </Tooltip>
          </ListItemIcon>
        </List>
      </Box>
    </>
  );
}

export default SidebarSubNav;
