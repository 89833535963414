import React, { useContext } from "react";

import { IWardListProps } from "./types";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  IDevice,
  IDeviceHistoryConfig,
  ITotal,
  IWard,
} from "../../models/Devices/types";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { HistoryOutlined } from "@mui/icons-material";
import { parseISO, format } from "date-fns";
import { ptBR } from "date-fns/locale";
import useDevices from "../../services/useDevices";
import { GlobalContext } from "../../contexts";
import { useQuery, UseQueryResult } from "react-query";
import { IError } from "../../models/Error/types";
import Dialog from "../Dialog/Dialog";
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 30,
  height: 15,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "& + .MuiSwitch-track": {
      backgroundColor: "#FF4F4F",
      opacity: 1,
      border: 0,
    },
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: theme.palette.primary.main,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      backgroundColor: "#ccc",
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 10,
    height: 10,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    padding: "10px 20px",
    fontSize: 14,
    fontWeight: "700",
    color: theme.palette.secondary.light,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: "700",
  },
  [`&.${tableCellClasses.root}`]: {
    boxShadow: "none",
    borderBottom: "none",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: "none",
  padding: "10px 0px",
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
interface IColumns {
  name: string;
  align: "left" | "center" | "right" | "justify" | "inherit" | undefined;
}
const columns: IColumns[] = [
  {
    name: "Unidade",
    align: "left",
  },
  {
    name: "Leitos",
    align: "center",
  },
  {
    name: "Dispositivos Ligados / Vinculados",
    align: "center",
  },
];

function Row(props: any) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow
        sx={(theme) => {
          return {
            backgroundColor:
              props.index % 2 === 0 ? "#f3f3f3 !important" : "white !important",
            "& > *": { borderBottom: "unset" },
            borderBottom: open ? `1px solid ${theme.palette.primary.main}` : "",
            "&:hover": {
              backgroundColor: "#fafafa !important",
              cursor: "pointer",
            },
          };
        }}
        onClick={() => setOpen(!open)}
      >
        <StyledTableCell
          align="left"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        >
          {props.title}
        </StyledTableCell>
        <StyledTableCell align="center">{props.total}</StyledTableCell>
        <StyledTableCell align="center">
          {props.actives} / {props.totalDevice}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases" sx={{ mb: "20px" }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Dispositivo</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center">Histórico</StyledTableCell>
                    <StyledTableCell align="center">
                      Ultima Ação
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.map((item: IDevice) => (
                    <StyledTableRow key={item._id}>
                      <StyledTableCell>{item.bed}</StyledTableCell>
                      <StyledTableCell align="center">
                        {item.online ? (
                          <IOSSwitch checked={item.status} />
                        ) : (
                          <IOSSwitch disabled />
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton onClick={(e) => props.handleClick(e, item)}>
                          <HistoryOutlined />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {item.date
                          ? format(
                              parseISO(item.date),
                              "dd/MM/yyyy à's' HH:mm",
                              {
                                locale: ptBR,
                              }
                            )
                          : "Não Informada"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}
interface IListWardProps {
  data: IWard[];
}
function ListWard({ data }: IListWardProps) {
  const { findOne } = useDevices();
  const { page } = useContext(GlobalContext);
  const [open, setOpen] = React.useState<boolean>(false);
  const [deviceToFind, setDeviceToFind] = React.useState<string>("");
  const [deviceName, setDeviceName] = React.useState<string>("");
  const deviceRef = React.useRef<any>();
  const getDevice: UseQueryResult<IDeviceHistoryConfig, IError> = useQuery(
    ["newDevice", deviceToFind],
    () => findOne(deviceToFind, page),
    { enabled: false }
  );
  function handleClose() {
    setOpen(false);
  }
  deviceRef.current = getDevice.refetch;
  React.useEffect(() => {
    deviceRef.current();
  }, [page]);
  function handleClick(_: any, value: IDevice) {
    setDeviceToFind(value.device);
    setDeviceName(value.bed);
    setOpen(true);
  }
  React.useEffect(() => {
    getDevice.refetch();
  }, [deviceToFind]);
  return (
    <>
      <Dialog
        open={open}
        loading={getDevice.isLoading}
        name={deviceName}
        count={
          getDevice.isSuccess
            ? Math.ceil(getDevice.data.total / getDevice.data.limit)
            : 0
        }
        handleClose={handleClose}
        history={getDevice.isSuccess ? getDevice.data.data : []}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <StyledTableCell key={item.name} align={item.align}>
                {item.name.toUpperCase()}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <Row
              total={item.total}
              totalDevice={item.total_device || 0}
              unityId={item._id}
              title={item.unity}
              data={item.results}
              actives={item.actives}
              handleClick={handleClick}
              index={index}
              key={index}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
}
export default ListWard;
