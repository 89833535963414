import React from "react";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import useDevices from "../../services/useDevices";
import { useQuery, UseQueryResult } from "react-query";
import { IError } from "../../models/Error/types";
import { IDevice } from "../../models/Devices/types";
import { Navigate } from "react-router-dom";
const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 0.3,
    },
  },
};
function Home() {
  const AnimatedBox = motion(Box);

  return (
    <AnimatedBox initial="hidden" variants={variants} animate="visible">
      <Navigate to={"/devices"} />
    </AnimatedBox>
  );
}

export default Home;
