import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { AnimatedOxygenLogo } from "../../../helpers";
import { motion } from "framer-motion";
import ConfigForm from "./ConfigForm";
function Config(){
    const theme = useTheme();
    const variants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1 } },
    };
    const AnimatedGrid = motion(Grid);

    return (
        <Grid
        container
        gap={2}
        padding={"20px"}
        sx={{
          overflowY: "auto",
        }}>
<ConfigForm></ConfigForm>
        </Grid>
    )
}

export default Config;