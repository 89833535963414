import React, { ReactNode } from "react";
import { Button as MuiButton, useTheme } from "@mui/material";
import { AnimatedOxygenLogo } from "../../helpers";
interface ButtonProps {
  children: ReactNode;
  loading?: boolean;
  type: any;
}
function Button({ children, loading, type = "button" }: ButtonProps) {
  const theme = useTheme();
  return (
    <MuiButton
      sx={{
        backgroundColor: "primary",
        fontFamily: "Rubik",
        fontWeight: "bold",
        boxShadow: "none",
        textTransform: "capitalize",
        fontSize: 18,
        width: "100%",
        padding: "15px",
        "&:hover": {
          boxShadow: "none",
        },
        "&.Mui-disabled": {
          backgroundColor: (theme) => theme.palette.divider,
        },
      }}
      disabled={loading}
      variant="contained"
      type={type}
    >
      {!loading ? (
        children
      ) : (
        <AnimatedOxygenLogo
          repeat
          split={1.3}
          fill={!!loading && theme.palette.primary.light}
        />
      )}
    </MuiButton>
  );
}

export default Button;
