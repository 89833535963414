import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../contexts";
interface RequireAuthProps {
  children: JSX.Element;
}
function RequireAuth({ children }: RequireAuthProps) {
  const { access_token } = useContext(AuthContext);
  if (!access_token) {
    return <Navigate to={"/login"} replace={true} />;
  }
  return children;
}

export default RequireAuth;
