import { useMutation, UseMutationResult } from "react-query";
import instance from "../configs/api";
import { IError } from "../models/Error/types";
import { IUnityProps } from "../components/Sidebar/Add/types";
function useBed() {
  const bedMutation: UseMutationResult<IUnityProps, IError, IUnityProps> =
    useMutation(async (values) => {
      return instance.post("/unity/store", values).then((res) => res.data);
    });
  return {
    bedMutation,
  };
}

export default useBed;
