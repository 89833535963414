import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import Yup from "../../../configs/yup";
import { FormTextField } from "../../../components/Forms";
import { IConfigDevice, IDevice } from "../../../models/Devices/types"
import { Notifier } from "../../../components";
import useDevices from "../../../services/useDevices";
import {  Grid, TextField, Typography } from "@mui/material";
import { Button } from "../../../components";
import { Autocomplete } from '@mui/material';
import useUnits from "../../../services/useUnit";

import { IUnit } from "../../../models/Unit/types";


function ConfigForm(){
    const { config,getfreeDevices,deviceFailure } = useDevices();
    const { getUnits } = useUnits();
    const [all,setAll] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [success,setSuccess] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [unidadeselect, setUnidadeSelect] = useState<IUnit>({} as IUnit);
    const [deviceselect, setDeviceSelect] = useState<IDevice>({} as IDevice);
    const [unidades, setUnidades] = useState<any[]>([]);
    const [devices,setDevices] = useState<any[]>([]);

    useEffect(()=>{
        if(getUnits.isSuccess){
            console.log(getUnits.data);
           setUnidades(getUnits.data);
        }
    },[getUnits])

    useEffect(()=>{
        if(all){
            getfreeDevices.mutate(unidadeselect,{
                onSuccess:(response)=>{
                    setDevices([response.data]);
                },
                onError:(error)=>{
                    setError(true);
                    setAll(false);
                    setErrorMessage(error.errorMessage);
                }
            })
           
        }
    },[all])


    
    const initialValues: IConfigDevice = {
        ip:"",
        idunidade:"",
        id:"",
        url:"",
        time:"",
        ssid:"",
        password:""
    }

    const times = [
        {label:"5 segundos",value:5000},{label:"10 segundos",value:10000},{label:"15 segundos",value:15000}
    ]

    const validation = Yup.object({
        ip:Yup.string().required("informe o ip do dispositivo"),
     //   id:Yup.string().required("informe um id"),
        

    })

    const handlerClearConfig=()=>{

    }
    
    const handlerSubmit = async (values:IConfigDevice,{resetForm}:any)=>{
      
        config.mutate(values,{
            onSuccess:(response)=>{
                resetForm();
                setAll(false);
                setSuccess(true);
                setErrorMessage("Dispositivo Configurado");
            
            },
            onError:(error)=>{
                resetForm();
                setAll(false)
                setError(true);
                console.log(error.errorMessage);
                setErrorMessage(error.errorMessage);

            }

           

        })
    }
  


    return (
        <Formik
            onSubmit={handlerSubmit}
            initialValues={initialValues}
            validationSchema={validation}
           
            
         >
                 {({values,initialValues,setFieldValue,resetForm})=>(
                     <Form style={{width:"100%"}}>
                          <Notifier severity="error" msg={errorMessage} open={error} closeTime={3000}  closeState={setError} />
                          <Notifier severity="success" msg={errorMessage} open={success} closeTime={3000}  closeState={setSuccess} />
                    <Grid container spacing={4} maxWidth={600}>
                          
                        <Grid item xs={12}>
                          <Autocomplete
                            id="idunidade"
                            options={unidades}
                            getOptionLabel={(option) => `${option.unity}-${option.bed}`}
                            renderInput={(params) => <TextField {...params} autoFocus     
                            error={!!error}
                            label="Unidade" variant="outlined"
                            /> }
                            onChange={(event: any, newValue:any) => {
                                if(newValue){
                                    setFieldValue(
                                        "idunidade",newValue._id
                                      );
                                      setAll(true);
                                      setUnidadeSelect(newValue);
                                    }else{
                                        setAll(false);
                                    }
                               
                            }}
                            fullWidth
                          />
                      
                        </Grid>
                        {all && (
                            <>
                            <Grid item xs={12}>
                            <FormTextField  label="Ip do dispositivo" name="ip" />
                            </Grid>
                          <Grid item xs={12}>
                          <Autocomplete
                            id="id"
                            options={devices}
                            getOptionLabel={(option) => option._id}
                            renderInput={(params) => <TextField {...params} autoFocus     
                            error={!!error}
                            label="Identificador de Dispositivo" variant="outlined"
                            /> }
                            onChange={(event: any, newValue:any) => {
                                if(newValue){
                                    console.log(newValue);
                                    setFieldValue(
                                        "id",newValue._id,
                                      );
                                    setFieldValue("url",newValue.url)
                                    setFieldValue("ssid",newValue.unity.company_id.ssid)
                                    setFieldValue("password",newValue.unity.company_id.password)
                                      
                                    }
                               
                            }}
                            fullWidth
                          />
                      
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            id="time"
                            options={times}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} autoFocus     
                            error={!!error}
                            label="Tempo de Requisição" variant="outlined"
                            /> }
                            onChange={(event: any, newValue:any) => {
                                if(newValue){
                                    setFieldValue(
                                        "time",newValue.value
                                      );
                                }
                            }}
                            fullWidth
                          />
                       
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField  label="SSID" name="ssid" />
                            </Grid>
                            <Grid item xs={12}>
                            <FormTextField  label="Password" name="password" />
                            </Grid>
                            <Grid item xs={12}>
                        <Button type="submit" loading={config.isLoading}>
                          Configurar
                        </Button>
                        </Grid>
                        <Grid item xs={12} >
                            { config.isSuccess && (
                                    <Typography >
                                        Dispositivo configurado com sucesso
                                    </Typography>
                            )}
          
            </Grid> 
                            </>
                            
                        )}
                       
                        </Grid>
                     </Form>
                 )}
             </Formik>

    )
    


}

export default ConfigForm;