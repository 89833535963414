import { Routes, Route } from "react-router-dom";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import Sidebar from "./components/Sidebar/Sidebar";
import { AppHelmet } from "./helpers";
import { Home, NotFound, Login } from "./pages";
import Devices from "./pages/Devices/Devices";
import Reports from "./pages/Reports/Reports";
import Settings from "./pages/Settings/Settings";
function AppRoutes() {
  return (
    <>
      <AppHelmet />
      <Routes>
        <Route element={<Sidebar />}>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          <Route path="/login2" element={<Home />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/reports" element={<Reports />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default AppRoutes;
