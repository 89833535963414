import { useMutation, UseMutationResult } from "react-query";
import instance from "../configs/api";
import { IUserForm, IUserMutation } from "../models/Auth/types";
import { IError } from "../models/Error/types";

function useAuth() {
  const loginMutation: UseMutationResult<IUserMutation, IError, IUserForm> =
    useMutation(async (values) => {
      return instance.post("/auth/login", values).then((res) => res.data);
    });
  return {
    loginMutation,
  };
}

export default useAuth;
