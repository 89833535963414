import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Button, FormTextField, Notifier } from "../..";
import { Formik, Form } from "formik";
import Yup from "../../../configs/yup";
import { useLocalStorage } from "../../../hooks";
import { IUnityProps } from "./types";
import useBed from "../../../services/useBed";
import { CloseRounded } from "@mui/icons-material";
interface IAddProps {
  setAdd: React.Dispatch<React.SetStateAction<boolean>>;
}
function Add({ setAdd }: IAddProps) {
  const { get } = useLocalStorage();
  const { bedMutation } = useBed();
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const initialValues = {
    unity: "",
    bed: "",
  };
  const validations = Yup.object().shape({
    unity: Yup.string().required("É requerido"),
    bed: Yup.string().required("É requerido"),
  });
  function handleSubmit(values: IUnityProps) {
    const companyId = get("user").company_id;
    values.company_id = companyId;
    bedMutation.mutate(values, {
      onSuccess: (response) => {
        setSuccess(true);
      },
      onError: (error) => {
        setError(true);
      },
      onSettled: () => {},
    });
  }
  return (
    <>
      <Notifier
        msg="Unidade Criada Com Sucesso!"
        closeState={setSuccess}
        severity="success"
        open={success}
      />
      <Notifier
        msg={bedMutation.error?.errorMessage || "Erro"}
        closeState={setError}
        severity="error"
        open={error}
      />
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize={16} fontWeight="bold" color="secondary">
          Adicionar Unidade
        </Typography>
        <IconButton onClick={() => setAdd(false)}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validations}
        >
          {({}) => (
            <Form>
              <Grid container spacing={2}>
                <Grid xs={12} item>
                  <FormTextField name="unity" label="Unidade"></FormTextField>
                </Grid>
                <Grid xs={12} item>
                  <FormTextField name="bed" label="Leito"></FormTextField>
                </Grid>
                <Grid xs={12} item>
                  <Button type={"submit"} loading={bedMutation.isLoading}>
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </>
  );
}

export default Add;
