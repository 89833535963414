import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ReactComponent as Splon } from "../../assets/svgs/splon.svg";
import { GlobalContext } from "../../contexts";
function SidebarNav() {
  const { sidebar, setSidebar } = useContext(GlobalContext);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        width: "100vw",
        background: "white",
        padding: "0px 22px",
        height: "80px",
        gap: "10px",
        position: "relative",
        borderBottom: `1px solid ${theme.palette.divider}`,
        flexDirection: "column",
      })}
    >
      <Box mt={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "none",
            gap: "10px",
          }}
        >
          <IconButton
            onClick={() => setSidebar((open) => !open)}
            sx={{
              display: { sm: "flex", md: "none" },
              maxHeight: "min-content",
              "&.MuiIconButton-root": {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: "white",
              },
            }}
          >
            {!sidebar ? (
              <ArrowBackIosRounded
                sx={{
                  height: "20px",
                  width: "20px",
                }}
              />
            ) : (
              <ArrowForwardIosRounded />
            )}
          </IconButton>
          <Splon />
        </Box>
        <Typography fontSize={14}>Dashboard de Monitoramento</Typography>
      </Box>
    </Box>
  );
}

export default SidebarNav;
