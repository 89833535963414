import React, { useContext } from "react";
import { Box, List, Divider, Drawer, useMediaQuery } from "@mui/material";
import SidebarContent from "./SidebarContent";
import SidebarNav from "./SidebarNav";
import SidebarSubNav from "./SidebarSubNav";
import SidebarUserProfile from "./SidebarUserProfile";
import { items } from "./Data/sidebarData";
import ListItemLink from "../ListItemLink/ListItemLink";
import { AuthContext, GlobalContext } from "../../contexts";
import { useLocation } from "react-router-dom";
import SidebarDevices from "./SidebarDevices";
import useDevices from "../../services/useDevices";
import { useQuery, UseQueryResult } from "react-query";
import { IActives } from "../../models/Devices/types";
import { IError } from "../../models/Error/types";
const navWidth = 300;
interface SidebarProps {}
function Sidebar(props: SidebarProps) {
  const matches = useMediaQuery("(min-width:900px)");
  const { access_token } = React.useContext(AuthContext);
  const location = useLocation();
  const { activeDevices } = useDevices();
  const [variant, setVariant] = React.useState<"persistent" | "temporary">(
    "persistent"
  );
  const query = useQuery<UseQueryResult<IActives, IError>>(
    "devicesStatus",
    () => activeDevices(),
    {
      refetchInterval: 3000,
    }
  );
  React.useEffect(() => {
    if (matches) {
      setVariant("persistent");
    } else {
      setVariant("temporary");
    }
  }, [matches]);
  const { sidebar, setSidebar } = useContext(GlobalContext);
  return (
    <>
      {access_token ? (
        <Box sx={{ display: "flex" }}>
          <Drawer
            sx={(theme) => ({
              height: "100vh",
              display: "flex",
              width: navWidth,
              flexDirection: "column",
              background: "white",
              borderRight: `1px solid ${theme.palette.divider}`,
              "& .MuiPaper-root": {
                width: navWidth,
              },
            })}
            open={sidebar}
            onClose={() => setSidebar(false)}
            variant={variant}
          >
            <SidebarUserProfile />
            <Box mt={9}>
              <List>
                <Divider variant="middle" />
                {items.map((item) => (
                  <ListItemLink
                    key={item.name}
                    name={item.name}
                    to={item.to}
                    icon={item.icon(
                      location.pathname === item.to ? true : false
                    )}
                  />
                ))}
              </List>

              <SidebarDevices status={query.data} />
            </Box>
          </Drawer>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                position: "fixed",
                width: "calc(100% - 280px)",
                zIndex: 1000,
              }}
            >
              <SidebarNav />
              <SidebarSubNav />
            </Box>

            <SidebarContent />
          </Box>
        </Box>
      ) : (
        <SidebarContent />
      )}
    </>
  );
}

export default Sidebar;
