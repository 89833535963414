import { useMediaQuery } from "@mui/material";
import React, { createContext } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { IActives } from "../models/Devices/types";
import { IError } from "../models/Error/types";
import { IGlobalContext, IGlobalProvider } from "../models/Global/types";
import useDevices from "../services/useDevices";

export const GlobalContext = createContext<IGlobalContext>(
  {} as IGlobalContext
);
export const GlobalProvider = ({ children }: IGlobalProvider) => {
  const [page, setPage] = React.useState<number>(1);
  const [view, setView] = React.useState<"list" | "block">("block");
  const [sidebar, setSidebar] = React.useState<boolean>(false);
  const matches = useMediaQuery("(min-width:900px)");
  React.useEffect(() => {
    if (matches) {
      setSidebar(true);
    }
  }, [matches]);
  return (
    <GlobalContext.Provider
      value={{
        page,
        setPage,
        view,
        setView,
        sidebar,
        setSidebar,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
