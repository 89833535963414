import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
interface Routes {
  path: string;
  tableLabel: string;
}
const routes: Routes[] = [
  { path: "/", tableLabel: "Inicio" },
  { path: "/login", tableLabel: "Entrar" },
  { path: "/devices", tableLabel: "Meus Dispositivos" },
  { path: "/reports", tableLabel: "Meus Relatórios" },
  { path: "/settings", tableLabel: "Configurações" },
];
function AppHelmet() {
  const location = useLocation();
  const [tabTitle, setTabTitle] = React.useState<string>("");
  React.useEffect(() => {
    const label = routes.find(
      (routeToFind) => routeToFind.path === location.pathname ?? routeToFind
    );
    if (label) {
      setTabTitle(label.tableLabel);
    } else {
      setTabTitle("404 Não Encontrado :/");
    }
  }, [location]);
  return (
    <HelmetProvider>
      <Helmet title={`Iottic - ${tabTitle}`} />
    </HelmetProvider>
  );
}
export default AppHelmet;
