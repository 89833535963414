import { HomeMiniRounded, LocalHospitalRounded } from "@mui/icons-material";
import HomeMini from "@mui/icons-material/HomeMini";
import { Box, Card, Grid, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { ResponsiveContainer } from "recharts";
const StyledCard = styled(Card)({
  padding: "10px",
});
function Reports() {
  return (
    <Box
      sx={{
        padding: "40px 20px",
      }}
    >
      <Box>
        <Typography fontSize={"2rem"} fontFamily={"Rubik"} fontWeight={"Bold"}>
          Relatórios Gráficos
        </Typography>
        <Typography fontFamily="Nunito" color="common.black">
          Bem vindo ao dashboard Splon
        </Typography>
      </Box>
      <Grid
        xs={12}
        container
        spacing={2}
        sx={{
          paddingTop: "10px",
        }}
      >
        <Grid item sm={12} md={4}>
          <StyledCard variant="outlined">
            {/* <ResponsiveContainer></ResponsiveContainer> */}
            <Typography>Graph 1</Typography>
          </StyledCard>
        </Grid>
        <Grid item sm={12} md={4}>
          <StyledCard variant="outlined">Graph2</StyledCard>
        </Grid>
        <Grid item sm={12} md={4} container>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Grid item xs={12}>
              <StyledCard
                variant="outlined"
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    background: "rgba(0, 204, 170, .1)",
                    display: "flex",
                    alignItems: "center",
                    padding: "8px",
                    borderRadius: "50%",
                  }}
                >
                  <LocalHospitalRounded color="primary" />
                </Box>
                <Box>
                  <Typography fontFamily={"Rubik"} fontSize="1em" fontWeight={"400"}>
                    <Typography
                      fontWeight={"bold"}
                      variant="inherit"
                      color="primary"
                      component={"span"}
                    >
                      30
                    </Typography>{" "}
                    Leitos Cadastrados
                  </Typography>
                </Box>
              </StyledCard>
            </Grid>
            <Grid item xs={12}>
              <StyledCard
                variant="outlined"
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    background: "rgba(0, 204, 170, .1)",
                    display: "flex",
                    alignItems: "center",
                    padding: "8px",
                    borderRadius: "50%",
                  }}
                >
                  <HomeMini color="primary" />
                </Box>
                <Box>
                  <Typography fontFamily={"Rubik"} fontSize="1em">
                    <Typography
                      fontWeight={"bold"}
                      variant="inherit"
                      color="primary"
                      component={"span"}
                    >
                      30
                    </Typography>{" "}
                    Dispositivos Cadastrados
                  </Typography>
                </Box>
              </StyledCard>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Reports;
