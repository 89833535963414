import axios from "axios";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import instance from "../configs/api";
import { IConfigDevice, IDeviceFreeMutation, IDeviceMutation, IFreeDevice, ITotal } from "../models/Devices/types";
import { IError } from "../models/Error/types";
import { IUnit } from "../models/Unit/types";

import { IWard } from "../models/Devices/types";
function useDevices() {
  const getMonitoringDevice: UseQueryResult<IWard[], IError> = useQuery(
    "device",
    async () => {
      return instance.get("/monitoring/getAllByClient").then((res) => res.data);
    },
    {
      refetchInterval: 3000,
    }
  );
  const activeDevices = () => {
    return instance.get("/monitoring/total").then((res) => res.data);
  };

  const getfreeDevices:UseMutationResult<IDeviceFreeMutation, IError, IUnit>=
      useMutation((values)=>{
          const { _id } = values;
          return instance.post("/device/link",{unity:_id})
      })
  

  const deviceFailure = (data:IFreeDevice) =>{
    return instance.post("/device/link/failure",{data}).then((res)=>res.data);
  } 
  const findOne = (id: string, page: number = 1) => {
    return instance
      .get(`/monitoring/get/${id}?limit=6&page=${page}`)
      .then((res) => res.data);
  };
  const config: UseMutationResult<IDeviceMutation, IError, IConfigDevice>=
        useMutation((values)=>{
        
            const {ip, id,idunidade, url, time, ssid, password } = values; 
            let urlBase = `http://${ip}:8080`;
            const urld = axios.create({
              baseURL:urlBase,
              timeout: 3000,
              withCredentials: false,
            });
            return urld.post(`/configuration`,
          {id:id,idunidade:idunidade,url:url,time:time,ssid:ssid,password:password}); 
        })
  return {
    findOne,
    activeDevices,
    config,
    getfreeDevices,
    deviceFailure,
    getMonitoringDevice,
  };
}

export default useDevices;
