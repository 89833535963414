function useLocalStorage<T>() {
  function set(key: string | any, values: T) {
    localStorage.setItem(key, JSON.stringify(values));
  }
  function get(key: string) {
    return JSON.parse(localStorage.getItem(key) || "{}");
  }
  function remove(key: string | any) {
    localStorage.removeItem(key);
  }
  return {
    set,
    get,
    remove,
  };
}
export default useLocalStorage;
