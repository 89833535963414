import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
interface ListItemLinkProps {
  icon?: React.ReactElement;
  name: string;
  to: string;
}
function ListItemLink(props: ListItemLinkProps) {
  const { icon, name, to } = props;
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <>
      <ListItem
        component={NavLink}
        to={to}
        button
        sx={{
          "&::before": {
            content: '""',
            position: "absolute",
            width: "3px",
            height: "25px",
            left: 0,
            transition: ".3s",
            background: match
              ? (theme) => theme.palette.primary.main
              : "transparent",
          },
        }}
      >
        <ListItemIcon color="red">{icon}</ListItemIcon>
        <ListItemText>
          <Typography
            color={!match ? "secondary.light" : "primary"}
            fontFamily="Nunito"
            fontWeight="700"
            fontSize="1rem"
            whiteSpace={"nowrap"}
          >
            {name}
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider variant="middle" />
    </>
  );
}

export default ListItemLink;
